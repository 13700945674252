@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");
body {
  font-family: "Poppins", sans-serif;
  color: #1e384b;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1e384b !important;
  font-family: "Open Sans", sans-serif !important;
}

.RoundedButton {
  border-radius: 15px;
}

.btn.btn-primary {
  background-color: #0085ad;
  border: #0085ad;
}
.btn.btn-primary:hover {
  background-color: #1e384b;
  border: #1e384b;
}

.btn.btn-green {
  background-color: #9ea700;
  border: #9ea700;
}
.btn.btn-green:hover {
  background-color: #798000;
  border: #798000;
}

.btn.btn-secondary {
  background-color: #e56a54;
  border: #e56a54;
}

.btn.btn-secondary:hover {
  background-color: #de3d21;
  border: #de3d21;
}

.spinner-border {
  color: #9b4f96;
}

.spinner-text {
  color: #9b4f96;
}
