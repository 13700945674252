.SamsungSplashScreen {
  background-image: url("../images/SamsungSplashScreen.jpg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
  background-attachment: fixed;
}

.verticallyCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
