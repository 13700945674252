.serialButton {
  border: 1px solid white !important;
}

th {
  color: #0085ad;
}

/* #UIElement {
  margin: 2vmin auto;
  text-align: center;
  font-size: medium;
  height: 40vh;
  width: 80vw;
} */
